import { Userpfp } from "../../../User-Components";
import { Link } from 'react-router-dom'
import { useContext } from "react";
import { APIContext } from "../../../../layers/api";
import './styling/author.css'
import { getShortDate } from "../../../Misc-Components";

// sz = [SM, M, L]
const Author = ({username, date=null, sz='SM', haveLink=true, softPFP=true}) => {
    const API = useContext(APIContext);

    if(haveLink) {
        return(
            <div className='Authorship'>
            <Link to={`/user/${username}`} className='Authorship-Link'>
                <Userpfp 
                    userURL={API.getUserAvatar(username)}
                    sz={sz}
                    softPFP={softPFP}
                    /> 
                <p>
                    by {username}
                    {
                        date && !isNaN(new Date(date).valueOf()) && 
                        <span style={{"color": "rgba(0,0,0,.65)", "fontWeight": "400"}}> <b style={{"padding":"0pt 1pt"}}>&middot;</b> {getShortDate(date)}</span>
                    }
                </p>
            </Link>
            </div>
        )
    }
    else {
        return (
        <div className='Authorship'>
            <Userpfp 
                userURL={API.getUserAvatar(username)}
                sz={sz}
                softPFP={softPFP}
                /> 
            <p>by {username}</p>
        </div>
        )
    }
}

export default Author;