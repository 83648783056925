import './styling/settings-subroute.css'
import './styling/Overview.css'
import { useNavigate } from 'react-router-dom'; 
import { AuthContext } from '../../layers';
import { useContext } from 'react';

// purely for readabilty purposes
const SSRHeader = ({children}) => <div className='SSR-Header'><h1>{children}</h1><hr/></div>;
// const OverviewSpan = ({children}) => <span></span>

const guestUserInfo = {
    username: 'Guest',
    dateJoined: 'N/A',
    id: 'N/A',
    bio: ''
}

const Overview = () => {
    const AUTH = useContext(AuthContext);
    const navigate = useNavigate();
    const {User} = AUTH;
    console.log("AUTH:", AUTH);
    console.log('User:', User);

    const UserInfo = User ?? guestUserInfo,
          signedIn = !!User;

    const onSignOut = () => {
        AUTH.signOut();
        navigate('/');
    }

    return(
        <div id="Settings-Overview" className='Settings-SubRoute'>
            <div className='SSR-Container'>
            <SSRHeader>
                Overview                
            </SSRHeader>

            <table>
                <tbody>
                    <tr>
                        <td>Display Name</td> 
                        <td>
                            {signedIn ? UserInfo.username : <span>#Guest</span>}
                        </td>
                    </tr>
                    <tr>
                        <td>Date Joined</td> <td>{UserInfo.dateJoined}</td>
                    </tr>
                    <tr>
                        <td>Account ID</td> 
                        <td>{signedIn ? `#${UserInfo.id}` : 'N/A'}</td>
                    </tr>
                    
                    <tr>
                        <td>Bio</td>
                    </tr>
                    <tr>
                        <td>"{UserInfo.bio}"</td>
                    </tr>
                </tbody>
            </table>

                <br/>

                <p>UrbanCoffee.io v0.7.1</p>
                <hr/>
                <button onClick={onSignOut}>Sign out</button>
            </div>
        </div>
    )
};

export default Overview;