import './styling/docCard.css'
import { Userpfp } from '../../User-Components'
import { getShortDate } from '../../Misc-Components'
import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { APIContext } from '../../../layers/api'

const EmptyDocCard = ({showAuthor}) => {
    return (
        <div className='Empty Doc-Card'>
            {showAuthor && <div className='Empty DC-Author'/>}
            <div className='Empty DC-Banner'/>
            <div className='Empty DC-Content'>
                <div className='Empty DC-Title'/>
                <div className='Empty DC-Subtitle'/>
            </div>
            <div className='Empty DC-Date'/>
        </div>
    )
}

const DocCard = ({doc, showAuthor = true}) => {
    // const navigate = useNavigate();
    const API = useContext(APIContext);

    if(!doc)
        return <EmptyDocCard showAuthor={showAuthor}/>

    const bannerSrc = `${API.getDocImg(doc._id, 'banner')}?sz=SM`;
    const style = {backgroundImage: `url(${bannerSrc})`}
    return(
        <Link to={`/article/${doc._id}`} className='Doc-Card-Link-Wrapper'>
        <article className='Doc-Card'>
            <div className='DC-Banner' style={style}/>
            <div className='DC-Content'>
                <h3 className='DC-Title'>{doc.title}</h3>
                <h4 className='DC-Subtitle'>{doc.subtitle}</h4>
                { showAuthor && 
                    <div className='DC-Author'>
                            <Userpfp 
                                userURL={API.getUserAvatar(doc.author)}
                                sz='SM'
                                softPFP
                                /> 
                            <div className="DC-Author-Info">
                                <span>{doc.author}</span><br/>
                                <span className='DC-Date'>
                                    {getShortDate(doc.timeCreated)}
                                </span>
                            </div>
                        {/* <span>&#8230;</span> */}
                    </div>
                }
            </div>
        </article>
        </Link>
    )
}

export default DocCard;